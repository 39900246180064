<template>
  <v-row justify="center">
    <v-dialog v-model="shower" scrollable persistent max-width="1000px">
      <!-- <template v-slot:activator="{ on }">
        <v-btn color="primary" dark v-on="on">Open Dialog</v-btn>
      </template>-->
      <v-card>
        <v-card-title
          >{{$t("uploadfile.upload_excel")}}<v-spacer></v-spacer>
          <v-icon :color="color.theme" dense dark size="35">mdi-file-upload</v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <!-- 
          :labelFileProcessingError="labelprocess"-->
          <!-- <v-card-text> -->
          <br />
          <v-layout>
            <!-- <v-flex lg10 class="mr-5"> -->
            <v-file-input
              v-model="files"
              color="deep-purple accent-4"
              counter
              label="File input"
              multiple
              placeholder="Select your files"
              prepend-icon="mdi-paperclip"
              outlined
              :show-size="1000"
            >
              <template v-slot:selection="{ index, text }">
                <v-chip v-if="index < 2" color="deep-purple accent-4" dark label small>
                  {{ text }}
                </v-chip>

                <span v-else-if="index === 2" class="text-overline grey--text text--darken-3 mx-2">
                  +{{ files.length - 2 }} File(s)
                </span>
              </template>
            </v-file-input>
            <!-- </v-flex> -->
          </v-layout>
          <!-- <br /> -->
          <v-layout><v-btn color="blue lighten-1" dark class="mr-1" @click="fn_load_employee()">{{$t("uploadfile.list_of_employees")}}</v-btn><v-btn color="teal lighten-1" dark @click="download_form()">{{$t("uploadfile.download_form")}}</v-btn></v-layout>
          
          <!-- <br/> -->
          <v-data-table
           v-if="showdata === true"
            class="elevation-0"
            :headers="headerstaff"
            :items="dataEmployee"
            :page.sync="pageE"
            :items-per-page="sizeE"
            :hide-default-footer="true"
            @page-count="pageCount = $event"
          >
            <!-- <template v-slot:[`header.account_id`]="{ header }"
              ><span :style="headerTable">{{ $t(header.text) }}</span></template
            > -->
            <template v-slot:[`header.user_name`]="{ header }"
              ><span :style="headerTable">{{ $t(header.text) }}</span></template
            >
            <template v-slot:[`header.oneid_user_id`]="{ header }"
              ><span :style="headerTable">{{ $t(header.text) }}</span></template
            >
            <template v-slot:[`header.business_email`]="{ header }"
              ><span :style="headerTable">{{ $t(header.text) }}</span></template
            >
            <template v-slot:[`header.mobile`]="{ header }"
              ><span :style="headerTable">{{ $t(header.text) }}</span></template
            >
            <template v-slot:item="props">
              <tr>
                <!-- <td width="5%">{{ props.item.account_id }}</td> -->
                <td width="30%" v-if="$t('default') === 'en'">{{ props.item.user_name_en }}</td>
                <td width="30%" v-else>{{ props.item.user_name_th }}</td>
                <td width="25%">{{ props.item.oneid_user_id }}</td>
                <td width="25%">{{ props.item.business_email }}</td>
                <td width="15%">{{ props.item.mobile }}</td>
              </tr>
            </template>
          </v-data-table>
          <br />
                  <v-layout row wrap justify-center>
                    <v-flex xs11 lg11 v-if="lengthdataEmployee > 0">
                      <v-pagination
                        :total-visible="10"
                        v-model="pageE"
                        :length="pageCountEM|| 0"
                        :color="color.theme"

                      ></v-pagination>
                    </v-flex>
                  </v-layout>
          <!-- <v-layout row wrap align-center>
            <v-card-subtitle>if duplicate all file will auto make copy</v-card-subtitle>
            <v-checkbox v-model="selectcopyortag" label="Tag version"></v-checkbox>
          </v-layout> -->
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :dark ="color.darkTheme" :color="color.theme" :loading="dis_upload" @click="fn_upload_excel()">{{$t("uploadfile.buttonupload")}}</v-btn>
          <v-btn
            :disable="dis_upload"
            color="red"
            class="white--text"
            @click="$emit('close'), cleardata()"
            :disabled="createprogress"
            >{{ $t("uploadfile.buttonclose") }}</v-btn
          >
          <!-- <v-btn color="primary" depressed @click="uploadfiles()" :loading="createprogress" :disabled="createprogress">อัปโหลดไฟล์</v-btn> -->
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import gbfGenerate from "@/globalFunctions/generateAuthorize";
import gbfdecodearraybuffer from "@/globalFunctions/decodearraybuffer";
// Import FilePond
import vueFilePond, { setOptions } from "vue-filepond";
// Import plugins
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm.js";
import FilePondPluginImagePreview from "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm.js";
// // Import styles
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
// Create FilePond component
const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginFileValidateSize);
import { saveAs } from "file-saver";
import { mapState, mapGetters } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { validationMixin } from "vuelidate";
import { required, maxLength, email } from "vuelidate/lib/validators";
const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});
export default {
  mixins: [validationMixin],
  validations: {
    foldername: { required },
  },
  components: { FilePond },
  props: ["show", "parentfolder", "switchapiupload", "remark", "oldactivefile", "departmentid"],
  data: function() {
    return {
      lengthdataEmployee:0,
      pageE:1,
      sizeE:20,
      showdata:false,
      dataEmployee:[],
        headerstaff: [
        // {
        //   text: "admin.account_id",
        //   align: "",
        //   value: "account_id",
        //   width: "5%",
        //   sortable: false,
        // },   
        {
          text: "admin.user_name",
          align: "",
          value: "user_name",
          width: "30%",
          sortable: false,
        },
        {
          text: "admin.oneid_user_id",
          align: "",
          value: "oneid_user_id",
          width: "25%",
          sortable: false,
        },
        {
          text: "admin.business_email",
          align: "",
          value: "business_email",
          width: "25%",
          sortable: false,
        },
         {
          text: "admin.mobile",
          align: "",
          value: "mobile",
          width: "15%",
          sortable: false,
        },
      ],
      dis_upload: false,
      hashtag: "",
      datahashtag: [],
      checkhashtag: false,
      valid: true,
      files: [],
      labelprocess: "",
      foldername: "",
      createprogress: false,
      uploadPercentage: 0,
      uploadProcess: "",
      uploadColor: "light-blue",
      uploadIndeterminate: false,
      listuploadfile: [],
      existingFiles: [],
      myFiles: [],
      selectcopyortag: false,
    };
  },
  computed: {
    ...mapState(["username", "authorize", "account_active", "color"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
    ]),
    shower: {
      get() {
        if (this.show === true) {
          this.$emit("closeDrag");
        }
        console.log("opendialog tag", this.remark, this.switchapiupload, this.oldactivefile);
        // เช็คค่าที่ส่งมาจาก dialog ว่าต้องสับ api ไหม
        // this.switchapiupload = true
        return this.show;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
    foldernameErrors() {
      const errors = [];
      if (!this.$v.foldername.$dirty) return errors;
      !this.$v.foldername.required && errors.push("กรุณากรอกชื่อ โฟลเดอร์");
      return errors;
    },
        pageCountEM: {
      get() {
        let l = this.lengthdataEmployee;
        let s = this.sizeE;
        return Math.ceil(l / s);
      },
      set(newName) {
        return newName;
      },
    },
        headerTable() {
      return (
        "color:" + this.color.theme + ";" + "font-weight: 600; font-size:15px;"
      );
    },
  },
  methods: {
    async download_form(){
     
      let auth = await gbfGenerate.generateToken();
      this.axios
          .get(process.env.VUE_APP_SERVICE_ADMIN + "/api/admin/download_setting_permission_form", {
             withCredentials: false,
            responseType: "arraybuffer",
            headers: { Authorization: auth.code },
          })
          .then((response) => {
            console.log('response',response)
            // if (response.statusText === "OK") {
            if (response.status === 200) {
               if (parseInt(response.headers["content-length"]) < 700 && (response.headers["content-type"]) === "application/json") {
                //this.opensnackbar = false;
                let res_error = gbfdecodearraybuffer.decodearraybuffer(response.data);
                Toast.fire({ icon: "error", title: res_error.errorMessage });
              } else {
                const blob = new Blob([response.data]);
                const content = response.headers["content-type"];
                saveAs(blob, "ตัวอย่างแบบฟอร์มกำหนดสิทธิ์.xlsx");
              }
              Toast.fire({
                icon: "success",
                title: "download success",
              });
            } else {
               Toast.fire({
                icon: "error",
                title: this.$t("toast.cannotconnectonebox"),
              });
            }
          });
    },
    async fn_upload_excel() {
      console.log(this.files);
       if(this.files.length === 0){
         Toast.fire({
          icon: "error",
          title:"คุณยังไม่ได้เลือกไฟล์",
        });
      }else{
      this.dis_upload = true;
      const formData = new FormData();
      console.log(this.files);
      formData.append(`file`, this.files[0]);
      formData.append(`account_admin`, this.dataAccountId);
      formData.append(`business_id`, this.dataAccountActive.business_info.business_id);
      let auth = await gbfGenerate.generateToken();
      let result = await this.axios({
        method: "POST",
        url: process.env.VUE_APP_SERVICE_ADMIN + "/api/admin/setting_permission_by_excel",
        data: formData,
        headers: { Authorization: auth.code },
      });
      console.log(result);
      if (result.data.status === "OK") {
        this.dis_upload = false;
        Toast.fire({
          icon: "success",
          title: "อัพโหลดสำเร็จ",
        });
        this.cleardata();
        this.$emit("close");
      }else{
        this.dis_upload = false;
        Toast.fire({
          icon: "error",
          title:result.data.errorCode+":"+result.data.errorMessage ,
        });
      }

       }
    },
    async fn_load_employee(){
      this.showdata = true
        let payload = {
        limit: "20",
        offset: "0",
        account_admin: this.dataAccountId,
        business_id: this.dataAccountActive.business_info.business_id,
        search_key: "",
      };
      let auth1 = await gbfGenerate.generateToken();
      this.axios
        .post(process.env.VUE_APP_SERVICE_ADMIN + "/api/admin/get_employee_name", payload, {
          headers: { Authorization: auth1.code },
        })
        .then((response) => {
          console.log(response);
          if(response.data.status === "OK"){
            this.lengthdataEmployee = response.data.result.employee_count_all;
            for(let i = 0;i<response.data.result.employee_list.length;i++){
              let data = {};
              data["account_id"] = response.data.result.employee_list[i]["account_id"]
              data["business_email"] = response.data.result.employee_list[i]["business_email"]
              data["mobile"] = response.data.result.employee_list[i]["mobile"]
              data["oneid_user_id"] = response.data.result.employee_list[i]["oneid_user_id"]
              data["user_name_en"] = response.data.result.employee_list[i]["user_name_en"]
              data["user_name_th"] = response.data.result.employee_list[i]["user_name_th"]
              this.dataEmployee.push(data);
            }
          }
        });

    },
    seticon(parameter) {
      console.log(parameter);
      // let parameter = parameter !== "" || parameter !== undefined || parameter !== null ? parameter.toLowerCase() : parameter
      let dataicon;
      if (parameter === "xlsx" || parameter === "xls") {
        dataicon = ["mdi-file-excel-outline", "green"];
      } else if (parameter === "pptx" || parameter === "ppt") {
        dataicon = ["mdi-file-powerpoint-outline", "orange"];
      } else if (parameter === "pdf") {
        dataicon = ["mdi-file-pdf-outline", "red"];
      } else if (parameter === "txt" || parameter === "txt") {
        dataicon = ["mdi-note-text-outline", "blue-grey"];
      } else if (
        parameter === "avi" ||
        parameter === "mpeg" ||
        parameter === "mov" ||
        parameter === "mp4" ||
        parameter === "mkv" ||
        parameter === "wmv" ||
        parameter === "3gp" ||
        parameter === "flv"
      ) {
        dataicon = ["mdi-file-video-outline", "purple"];
      } else if(parameter === "shortcut"){
      dataicon=["mdi-file-link-outline","pink"]
    }
      else {
        dataicon = ["mdi-file-question", "blue-grey"];
      }
      return dataicon;
    },
    cleardata() {
      //console.log(this.files);
      this.myFiles = [];
      this.files = [];
      this.createprogress = false;
      this.uploadPercentage = 0;
      this.listuploadfile = [];
    },
  },
};
</script>
